import { useSyncExternalStore } from 'react';

import { getStore } from '../lib/store';

const store = getStore();

export type DrawState = ReturnType<typeof useDrawState>;

export const useDrawState = () => {
  return useSyncExternalStore(store.subscribe, store.getSnapshot);
};
