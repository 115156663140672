import React from 'react';

import { GameState } from '../lib/store';
import Pointer from '../pointer';

type PointersProps = {
  positions: GameState['positions'];
  taps: GameState['taps'];
  users: GameState['users'];
};

export const Pointers = (props: PointersProps) => {
  const { positions, taps, users } = props;

  return (
    <>
      {Object.keys(positions).map((key) => {
        const position = positions[key];
        const userTaps = taps[key];
        const user = users[key];

        if (!user) return null;

        return (
          <Pointer
            key={key}
            x={position.x}
            y={position.y}
            taps={userTaps}
            color={user.color}
            name={user.name}
            hidden={position.hidden}
          />
        );
      })}
    </>
  );
};
