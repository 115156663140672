import React, { useState } from 'react';
import {
  animated,
  config,
  easings,
  useSpring,
  useTransition,
} from '@react-spring/web';

import { Straw as TStraw } from '../../types/straw';
import { User } from '../../types/user';
import Confetti from '../confetti/confetti';
import * as socket from '../lib/socket';

function getWinnerEffects() {
  return (
    <div className="winner-underlay">
      <i className="far fa-star"></i>
      <i className="far fa-star"></i>
      <Confetti />
    </div>
  );
}

function getUser(user: User, showCancelText: boolean) {
  const transitions = useTransition(user, {
    config: config.wobbly,
    from: {
      transform: `translate3d(calc(-50% + 15px), 100px, 0) rotate(45deg) scale(1)`,
      opacity: 0,
    },
    enter: {
      transform: `translate3d(calc(-50% + 15px), 0px, 0) rotate(0deg) scale(1)`,
      opacity: 1,
    },
    leave: {
      transform: `translate3d(calc(-50% + 15px), -150px, 0) rotate(-180deg) scale(0.5)`,
      opacity: 0,
      config: {
        easing: easings.easeInCubic,
        duration: 500,
      },
    },
  });

  return transitions((style, item) =>
    item ? (
      <animated.div
        className="straw-user"
        style={{
          whiteSpace: 'nowrap',
          backgroundColor: showCancelText ? 'white' : item.color,
          ...style,
        }}
      >
        {showCancelText ? 'Press to cancel' : item.name}
      </animated.div>
    ) : null
  );
}

type StrawProps = {
  straw: TStraw;
  disabled: boolean;
  userClaimed: boolean;
  strawCount: number;
  index: number;
  onStrawClicked: (index: number) => void;
  users: Record<string, User>;
  winner: string;
  canCancel: boolean;
};

export default function Straw(props: StrawProps) {
  const [active, setActive] = useState(false);
  const revealedLength = props.straw.length;
  const length = revealedLength || 25;
  const claimedClass =
    props.straw.userId && !active
      ? 'straw-container--claimed'
      : 'straw-container--unclaimed';

  const baseTransform = active ? '-10%' : '0%';

  const containerStyles = useSpring({
    config: { tension: 200, friction: 10 },
    transform: `translate3d(0, ${revealedLength ? '-100%' : baseTransform}, 0)`,
  });

  const canInteract =
    props.canCancel ||
    (!props.disabled && !props.userClaimed && !props.straw.userId);

  return (
    <animated.div
      className={`straw-container ${claimedClass}`}
      onMouseOver={() => canInteract && setActive(true)}
      onMouseOut={() => setActive(false)}
      style={{
        height: `${length}%`,
        left: `calc(((100% / ${props.strawCount + 1}) - 15px) * ${
          props.index + 1
        })`,
        ...containerStyles,
      }}
    >
      {props.straw.userId &&
        (props.winner === props.straw.userId ? getWinnerEffects() : null)}
      <div
        className="straw"
        style={{
          backgroundColor: props.straw.colour,
          height: `100%`,
          left: 0,
        }}
        onClick={
          props.disabled
            ? undefined
            : (e) => {
                e.stopPropagation();
                setActive(false);
                props.canCancel
                  ? socket.cancelStraw()
                  : props.onStrawClicked(props.index);
              }
        }
      >
        {getUser(props.users[props.straw.userId], props.canCancel && active)}
        {active && !props.canCancel && (
          <div className="straw-press-hint">Press to choose!</div>
        )}
      </div>
    </animated.div>
  );
}
