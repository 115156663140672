import { PHASES } from '../../types/game-phases';
import { Straw } from '../../types/straw';
import { User } from '../../types/user';

import { playSound, SOUNDS, stopSound } from './sound';

let storeInstance: Store;

export type GameState = {
  phase: PHASES;
  roomId: string | null;
  users: Record<string, User>;
  spectators: number;
  straws: Array<Straw>;
  positions: Record<string, { x: number; y: number; hidden: boolean }>;
  taps: Record<string, number>;
  isSpectator: boolean;
  purpose: string;
  winner: string;
  hasClaimed: boolean;
  usersClaimed: Record<string, boolean>;
  connected: boolean;
  userId: string;
};

export class Store {
  state: GameState = {
    phase: PHASES.START,
    roomId: null,
    users: {},
    spectators: 0,
    straws: [],
    positions: {},
    taps: {},
    isSpectator: false,
    purpose: '',
    winner: '',
    hasClaimed: false,
    usersClaimed: {},
    connected: false,
    userId: '',
  };

  updateTimer: ReturnType<typeof setTimeout> | null = null;
  updatePending = false;

  subscriptions: Array<() => void> = [];

  reset() {
    this.state = {
      phase: PHASES.START,
      roomId: null,
      users: {},
      spectators: 0,
      straws: [],
      positions: {},
      taps: {},
      isSpectator: false,
      purpose: '',
      winner: '',
      hasClaimed: false,
      usersClaimed: {},
      connected: this.state.connected,
      userId: '',
    };
    if (this.updateTimer) clearTimeout(this.updateTimer);
    this.notifySubscriptions();
  }

  subscribe = (callback: () => void) => {
    this.subscriptions.push(callback);
    return () => {
      this.subscriptions = this.subscriptions.filter((cb) => cb !== callback);
    };
  };

  getSnapshot = () => {
    return this.state;
  };

  notifySubscriptions = () => {
    this.updatePending = false;
    this.subscriptions.map((cb) => cb());
  };

  update<K extends keyof GameState>(key: K, value: GameState[K]) {
    this.state = { ...this.state, [key]: value };

    if (!this.updatePending) {
      this.updateTimer = setTimeout(this.notifySubscriptions, 0);
      this.updatePending = true;
    }
  }

  setPhase(newPhase: PHASES) {
    const { winner, straws } = this.state;
    this.update('phase', newPhase);

    if (newPhase === PHASES.REVEAL) {
      playSound(SOUNDS.DRUMROLL, { loop: true });
    }

    if (newPhase === PHASES.RESULT && !winner) {
      stopSound(SOUNDS.DRUMROLL);
      playSound(SOUNDS.CYMBAL);
      const shortStraw = straws.find((straw) => straw.length === 25);
      if (shortStraw) this.update('winner', shortStraw.userId);
    }
  }

  setConnected(isConnected: boolean) {
    this.update('connected', isConnected);
  }

  setIsSpectator(isSpectator: boolean) {
    this.update('isSpectator', isSpectator);
  }

  setRoomId(roomId: string) {
    this.update('roomId', roomId);
  }

  setUsers(users: Record<string, User>) {
    this.update('users', users);
  }

  setTap(userId: string) {
    const { taps } = this.state;
    this.update('taps', {
      ...taps,
      [userId]: taps[userId] ? taps[userId] + 1 : 1,
    });
  }

  setStraws(straws: Array<Straw>) {
    const { hasClaimed, userId } = this.state;
    this.update('straws', straws);
    if (!hasClaimed && straws.some((straw) => straw.userId === userId)) {
      this.update('hasClaimed', true);
    }
  }

  setPurpose(purpose: string) {
    this.update('purpose', purpose);
  }

  setPositions(positionData: { id: string; x: number; y: number }) {
    const { usersClaimed, positions } = this.state;
    if (!usersClaimed[positionData.id]) {
      this.update('positions', {
        ...positions,
        [positionData.id]: {
          x: positionData.x,
          y: positionData.y,
          hidden: false,
        },
      });
    }
  }

  setSpectatorCount(spectatorCount: number) {
    this.update('spectators', spectatorCount);
  }

  setStrawChosen(choiceData: { straws: Array<Straw>; userClaimed: string }) {
    const { usersClaimed, userId, positions } = this.state;
    const newPositions = { ...positions };
    if (newPositions[choiceData.userClaimed]) {
      newPositions[choiceData.userClaimed].hidden = true;
    }
    this.update('positions', newPositions);
    this.update('straws', choiceData.straws);
    this.update('usersClaimed', {
      ...usersClaimed,
      [choiceData.userClaimed]: true,
    });
    if (choiceData.userClaimed === userId) {
      this.update('hasClaimed', true);
    }
  }

  setStrawCancelled(cancelData: {
    straws: Array<Straw>;
    userCancelled: string;
  }) {
    const { usersClaimed, userId, positions } = this.state;
    const newPositions = { ...positions };
    if (newPositions[cancelData.userCancelled]) {
      newPositions[cancelData.userCancelled].hidden = false;
    }
    this.update('positions', newPositions);
    this.update('straws', cancelData.straws);
    this.update('usersClaimed', {
      ...usersClaimed,
      [cancelData.userCancelled]: false,
    });
    if (cancelData.userCancelled === userId) {
      this.update('hasClaimed', false);
    }
  }

  setUserId(userId: string) {
    this.update('userId', userId);
  }
}

export const getStore = () => {
  if (!storeInstance) storeInstance = new Store();
  return storeInstance;
};

export const resetStore = () => {
  storeInstance.reset();
};
