import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import config from './config';

if (config.sentry.enabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
  });
}

import MainPage from './react/main-page';

const reactRoot = document.getElementById('__react-root__');

console.log(
  "😬 Hi Ace! It's no use inspecting the page during play. The straws all have the same length in your browser until they are all chosen."
);

if (reactRoot) {
  const root = createRoot(reactRoot);
  root.render(<MainPage />);
}
