import { MouseEvent } from 'react';
import _throttle from 'lodash.throttle';

import * as socket from '../lib/socket';

import { DrawState } from './use-draw-state';

const broadcastMove = _throttle((x, y) => {
  const { innerWidth, innerHeight } = window;
  socket.setPosition((x / innerWidth) * 100, (y / innerHeight) * 100);
}, 100);

const broadcastTap = _throttle(() => {
  socket.tap();
}, 100);

export const usePointerEvents = (drawState: DrawState) => {
  const onMouseMove = (e: MouseEvent) => {
    if (
      (drawState.phase !== 'Draw' && drawState.phase !== 'CollectNames') ||
      drawState.isSpectator
    )
      return;
    if (drawState.straws.some((straw) => straw.userId === drawState.userId))
      return null;
    e.preventDefault();
    broadcastMove(e.clientX, e.clientY);
  };

  const onClick = () => {
    if (
      (drawState.phase !== 'Draw' && drawState.phase !== 'CollectNames') ||
      drawState.isSpectator
    )
      return;
    if (drawState.straws.some((straw) => straw.userId === drawState.userId))
      return null;
    broadcastTap();
  };

  return { onMouseMove, onClick };
};
