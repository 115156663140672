import { io, Socket } from 'socket.io-client';

import {
  ClientToServerEvents,
  ServerToClientEvents,
} from '../../types/socket-server';

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io();

export const getSocket = () => {
  return socket;
};

export const createRoom = () => {
  socket.emit('createRoom');
};

export const setPurpose = (purpose: string) => {
  socket.emit('setPurpose', purpose);
};

export const setName = (name: string) => {
  socket.emit('setName', name);
};

export const startDraw = () => {
  socket.emit('startDraw');
};

export const setPosition = (x: number, y: number) => {
  socket.emit('setPosition', x, y);
};

export const tap = () => {
  socket.emit('userTapped');
};

export const joinRoom = (roomId: string) => {
  socket.emit('joinRoom', roomId);
};

export const leaveRoom = () => {
  socket.emit('leaveRoom');
};

export const chooseStraw = (strawId: number) => {
  socket.emit('chooseStraw', strawId);
};

export const cancelStraw = () => {
  socket.emit('cancelStraw');
};
