import React from 'react';
import { animated, easings, useTransition } from '@react-spring/web';

type ChooseStrawHintProps = {
  visible: boolean;
};

export const ChooseStrawHint = ({ visible }: ChooseStrawHintProps) => {
  const transitions = useTransition(visible, {
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },
    from: {
      transform: `scale(0.3) translateY(-150px)`,
      opacity: 0,
    },
    enter: {
      transform: `scale(1) translateY(0px)`,
      opacity: 0.3,
    },
    leave: {
      transform: `scale(0) translateY(150px)`,
      opacity: 0,
    },
  });

  return transitions((style, visible) =>
    visible ? (
      <animated.p className="hint" style={style}>
        Choose a straw
        <br />
        <i className="fas fa-arrow-down"></i>
      </animated.p>
    ) : null
  );
};
