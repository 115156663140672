import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import { useDrawState } from '../hooks/use-draw-state';
import * as socket from '../lib/socket';
import { Pointers } from '../pointers';
import Users from '../users';

export default function CollectNames() {
  const { users, userId, positions, taps } = useDrawState();
  const [clipboardStatus, setClipboardStatus] = useState('Copy shareable link');

  const inputEl = useRef<HTMLInputElement>(null);

  const onGoButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    if (!inputEl?.current?.value?.trim()) return;
    try {
      window.localStorage.setItem('savedName', inputEl.current.value);
    } catch (e) {
      // no-op
    }
    socket.setName(inputEl.current.value);
  };

  const onStartButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    socket.startDraw();
  };

  const onClipboardButtonClick = () => {
    navigator.clipboard
      .writeText(window.location.toString())
      .then(() => {
        setClipboardStatus('Link copied!');
      })
      .catch(() => {
        setClipboardStatus('Link copy failed. Sorry.');
      });
  };

  useEffect(() => {
    try {
      if (!inputEl?.current?.value && inputEl.current) {
        inputEl.current.value = window.localStorage.getItem('savedName') ?? '';
      }
    } catch (e) {
      // no-op
    }
    inputEl.current && inputEl.current.focus();
  });

  const renderInput = () => {
    if (users[userId]) return null;
    return (
      <div className="center">
        <p>Enter your name to join!</p>
        <form>
          <input
            style={{ width: 300, minWidth: 300 }}
            type="text"
            ref={inputEl}
          />
          <button onClick={onGoButtonClick}>Enter</button>
        </form>
      </div>
    );
  };

  return (
    <div>
      <Pointers users={users} positions={positions} taps={taps} />
      <Users />
      <div className="center">
        <p>
          When everyone is in, choose a straw. Shortest straw wins (
          <em>or loses</em>)!
        </p>
      </div>
      {renderInput()}

      {Object.keys(users).length > 1 ? (
        <div className="center">
          <p>If everybody&apos;s here - you can get started:</p>
          <button onClick={onStartButtonClick}>Everybody&apos;s in!</button>
        </div>
      ) : (
        <div>
          <p className="center">Waiting for more people...</p>
          <p className="center">
            Share the URL with everyone you want to join:
          </p>
          <p className="center small">
            <button
              className="clipboard-button"
              onClick={onClipboardButtonClick}
            >
              <i className="far fa-clipboard"></i> {clipboardStatus}
            </button>
          </p>
        </div>
      )}
    </div>
  );
}
