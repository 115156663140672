import React, { useEffect, useRef } from 'react';
import { animated, to, useSpring } from '@react-spring/web';

const trans = (x: number, y: number) =>
  `translate3d(${(x / 100) * window.innerWidth - 10}px,${
    (y / 100) * window.innerHeight - 10
  }px,0)`;

type PointerProps = {
  x: number;
  y: number;
  taps: number;
  color: string;
  name: string;
  hidden: boolean;
};

export default function Pointer(props: PointerProps) {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const [pointer, setPointer] = useSpring(() => ({
    xy: [50, 0],
    config: { friction: 26, clamp: true },
  }));

  const [scale, setScale] = useSpring(() => ({
    scale: 1,
    config: { friction: 10 },
  }));

  // FIXME: this runs a lot
  setPointer({ xy: [props.x, props.y] });

  useEffect(() => {
    clearTimeout(timerRef.current);
    setScale({ scale: 1.5 });
    timerRef.current = setTimeout(() => {
      setScale({ scale: 1 });
    }, 250);
  }, [props.taps]);

  const nameClass =
    props.x <= 50 ? 'pointer__name--right' : 'pointer__name--left';

  return (
    <animated.span
      className="pointer"
      style={{
        transform: to(
          [pointer.xy.to(trans), scale.scale.to((v) => `scale(${v})`)],
          (translate, scale) => `${translate} ${scale}`
        ),
        backgroundColor: props.color,
        visibility: props.hidden ? 'hidden' : 'visible',
      }}
    >
      <span className={`pointer__name ${nameClass}`}>{props.name}</span>
    </animated.span>
  );
}
