import React from 'react';
import { animated, easings, useTransition } from '@react-spring/web';

type DrawCompleteProps = {
  visible: boolean;
};

export const DrawComplete = ({ visible }: DrawCompleteProps) => {
  const transitions = useTransition(visible, {
    config: { tension: 300, friction: 5 },
    from: {
      transform: `translate(0px, -50px) scale(0.3)`,
      opacity: 0,
    },
    enter: {
      transform: `translate(0px, 0px) scale(1)`,
      opacity: 1,
    },
    leave: {
      transform: `translate(0px, -300px) scale(0)`,
      opacity: 1,
      config: {
        easing: easings.easeOutCirc,
        duration: 500,
      },
    },
  });

  return transitions((style, visible) =>
    visible ? (
      <animated.div style={style} className="winner center">
        <p>Draw complete!</p>
      </animated.div>
    ) : null
  );
};
