import { useEffect } from 'react';
import confetti from 'canvas-confetti';

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#ecf0f1',
  '#95a5a6',
];

export default function Confetti() {
  useEffect(() => {
    let timer: number | null;
    const end = Date.now() + 15 * 1000;

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors,
      });

      if (Date.now() < end) {
        timer = requestAnimationFrame(frame);
      }
    })();

    return () => {
      if (timer) cancelAnimationFrame(timer);
    };
  }, []);

  return null;
}
