type EnvironmentConfig = {
  baseUrl: string;
  slack: {
    oauthAuthoriseUrl: string;
    clientId: string;
    redirectUrl: string;
  };
  sentry: {
    enabled: boolean;
    dsn: string;
  };
};

const RUNTIME_ENV = process.env.RUNTIME_ENV ?? 'local';

const envConfig: EnvironmentConfig = Object.freeze(
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require(`./${RUNTIME_ENV}.json`)
);

export default envConfig;
