import React from 'react';
import {
  animated,
  useChain,
  useSpringRef,
  useTransition,
} from '@react-spring/web';

import getColor from '../../server/lib/random-color';
import { playSound, SOUNDS } from '../lib/sound';

type CountDownDefinition = Array<{ counter: number | string; color: string }>;

export default React.memo(function Countdown({
  isActive,
}: {
  isActive: boolean;
}) {
  const data: CountDownDefinition = isActive
    ? [
        { counter: 3, color: getColor(0) },
        { counter: 2, color: getColor(3) },
        { counter: 1, color: getColor(6) },
        { counter: 'GO!', color: getColor(9) },
      ]
    : [];

  const transitionRef = useSpringRef();

  const transitions = useTransition(data, {
    ref: transitionRef,
    unique: true,
    trail: 500,
    from: { transform: 'translate3d(100%, 0, 0)' },
    enter: { transform: 'translate3d(0%, 0, 0)' },
    leave: { transform: 'translate3d(-100%, 0, 0)' },
    onStart: (_, __, item) => {
      if (!isActive) return;
      switch (item.counter) {
        case 3:
          playSound(SOUNDS.THREE);
          break;
        case 2:
          playSound(SOUNDS.TWO);
          break;
        case 1:
          playSound(SOUNDS.ONE);
          break;
      }
      return;
    },
  });

  useChain([transitionRef]);

  return (
    <div className="countdown-container">
      {transitions((style, item) => {
        return (
          <animated.div
            className="countdown-slide"
            style={{ ...style, backgroundColor: item.color }}
          >
            <p className="countdown-number">{item.counter}</p>
          </animated.div>
        );
      })}
    </div>
  );
});
