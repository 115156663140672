import React from 'react';
import { animated, config, useTransition } from '@react-spring/web';

import CollectNames from '../collect-names';
import Countdown from '../countdown';
import Disconnected from '../disconnected/disconnected';
import Draw from '../draw';
import { useDrawState } from '../hooks/use-draw-state';
import { useGameSounds } from '../hooks/use-game-sounds';
import { useNavigation } from '../hooks/use-navigation';
import { usePointerEvents } from '../hooks/use-pointer-events';
import { useSocketEvents } from '../hooks/use-socket-events';
import Start from '../start';

const components = {
  Start,
  CollectNames,
  Countdown: CollectNames,
  Draw,
  Reveal: Draw,
  Result: Draw,
  Disconnected: Disconnected,
};

export default function MainPage() {
  const drawState = useDrawState();
  const Component =
    drawState.connected || drawState.phase === 'Start'
      ? components[drawState.phase]
      : components['Disconnected'];

  const transitions = useTransition(drawState.purpose, {
    config: config.wobbly,
    from: { transform: `translate3d(0, -200px, 0)` },
    enter: { transform: `translate3d(0, 0, 0)` },
    leave: { transform: `translate3d(0, -200px, 0)` },
  });

  const { onMouseMove, onClick } = usePointerEvents(drawState);
  useNavigation(drawState);
  useGameSounds(drawState);
  useSocketEvents();

  return (
    <div className="main" onPointerMove={onMouseMove} onClick={onClick}>
      <h1>Draw the Short Straw</h1>
      <div className="purpose-container">
        {transitions((style, item) =>
          item ? (
            <animated.p className="purpose center nachos" style={style}>
              {item}
            </animated.p>
          ) : null
        )}
      </div>
      <Component />
      <Countdown isActive={drawState.phase === 'Countdown'} />
    </div>
  );
}
