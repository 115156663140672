import { Howl, Howler } from 'howler';

Howler.volume(0.2);

export enum SOUNDS {
  MUSIC,
  SQUEAK,
  GONG,
  CHEER,
  SLIDEUP,
  WHISTLE,
  POP,
  THREE,
  TWO,
  ONE,
  BURP1,
  BURP2,
  BURP3,
  BURP4,
  BURP5,
  HORN,
  DRUMROLL,
  CYMBAL,
}

const BURPS = [
  SOUNDS.BURP1,
  SOUNDS.BURP2,
  SOUNDS.BURP3,
  SOUNDS.BURP4,
  SOUNDS.BURP5,
];

const allSounds = new Map(
  Object.entries({
    [SOUNDS.MUSIC]: '/music/music.m4a',
    [SOUNDS.SQUEAK]: '/sound/squeak.mp3',
    [SOUNDS.GONG]: '/sound/gong.mp3',
    [SOUNDS.CHEER]: '/sound/cheer.mp3',
    [SOUNDS.SLIDEUP]: '/sound/whoosh.mp3',
    [SOUNDS.WHISTLE]: '/sound/whistle.mp3',
    [SOUNDS.POP]: '/sound/pop.mp3',
    [SOUNDS.HORN]: '/sound/horn.mp3',
    [SOUNDS.THREE]: '/sound/three.mp3',
    [SOUNDS.TWO]: '/sound/two.mp3',
    [SOUNDS.ONE]: '/sound/one.mp3',
    [SOUNDS.BURP1]: '/sound/burp1.mp3',
    [SOUNDS.BURP2]: '/sound/burp2.mp3',
    [SOUNDS.BURP3]: '/sound/burp3.mp3',
    [SOUNDS.BURP4]: '/sound/burp4.mp3',
    [SOUNDS.BURP5]: '/sound/burp5.mp3',
    [SOUNDS.DRUMROLL]: '/sound/drumroll.mp3',
    [SOUNDS.CYMBAL]: '/sound/cymbal-crash.mp3',
  })
);

type Howls = Record<string, Howl>;
type PlaySoundOpts = { volume?: number; loop?: boolean };

const howls = {} as Howls;

for (const [key, value] of allSounds) {
  howls[key] = new Howl({ src: value });
}

export const playSound = (
  name: SOUNDS,
  opts: PlaySoundOpts = { volume: 1, loop: false }
) => {
  howls[name].loop(opts.loop ?? false);
  howls[name].volume(opts.volume ?? 1);
  howls[name].play();
};

export const playBurp = () => {
  const burpId = Math.round(Math.random() * 4);
  playSound(BURPS[burpId]);
};

export const stopSound = (name: SOUNDS) => {
  howls[name].stop();
};

export const stopAll = () => {
  Howler.stop();
};
