import { useEffect } from 'react';

import { getSocket } from '../lib/socket';
import { playBurp, playSound, SOUNDS, stopAll } from '../lib/sound';
import { getStore } from '../lib/store';

const socket = getSocket();
const store = getStore();

export const useSocketEvents = () => {
  useEffect(() => {
    if (socket.connected) {
      store.setUserId(socket.id);
      store.setConnected(true);
    }

    socket.on('connect', () => {
      store.setUserId(socket.id);
      store.setConnected(true);
    });

    socket.on('disconnect', () => {
      store.setConnected(false);
      stopAll();
    });

    socket.on('roomCreated', (roomData) => {
      store.setIsSpectator(true);
      store.setRoomId(roomData.roomId);
      if (!window.location.pathname.split('/')[2]) {
        window.history.pushState({}, 'enter room', `/draw/${roomData.roomId}`);
      }
      store.setPhase(roomData.phase);
      store.setUserId(socket.id);
    });

    socket.on('phaseUpdated', (newPhase) => {
      store.setPhase(newPhase);
    });

    socket.on('usersUpdated', (newUsers) => {
      store.setUsers(newUsers);
    });

    socket.on('userTapped', (userId) => {
      playBurp();
      store.setTap(userId);
    });

    socket.on('strawsUpdated', (newStraws) => {
      store.setStraws(newStraws);
    });

    socket.on('roomJoined', (roomData) => {
      if (!window.location.pathname.split('/')[2]) {
        window.history.pushState({}, 'enter room', `/draw/${roomData.roomId}`);
      }

      store.setRoomId(roomData.roomId);
      store.setUsers(roomData.users);
      store.setStraws(roomData.straws);
      store.setPhase(roomData.phase);
      store.setPurpose(roomData.purpose);
      store.setIsSpectator(true);
      store.setUserId(socket.id);
    });

    socket.on('userPositionUpdated', (positionData) => {
      store.setPositions(positionData);
    });

    socket.on('roomNotFound', () => {
      store.setRoomId('');
      store.reset();
      window.history.replaceState({}, 'homepage', `/`);
    });

    socket.on('spectatorStateUpdated', (spectatorState) => {
      store.setIsSpectator(spectatorState);
    });

    socket.on('purposeUpdated', (newPurpose) => {
      store.setPurpose(newPurpose);
    });

    socket.on('viewerCountUpdated', (viewerCount) => {
      store.setSpectatorCount(viewerCount);
    });

    socket.on('strawChosen', (choiceData) => {
      playSound(SOUNDS.SQUEAK);
      store.setStrawChosen(choiceData);
    });

    socket.on('strawCancelled', (cancelData) => {
      playSound(SOUNDS.HORN);
      store.setStrawCancelled(cancelData);
    });

    return function cleanup() {
      socket.removeAllListeners();
    };
  }, []);
};
