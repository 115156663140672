import React, { Fragment } from 'react';
import { animated, useTransition } from '@react-spring/web';

import { PHASES } from '../../types/game-phases';
import { User } from '../../types/user';
import { useDrawState } from '../hooks/use-draw-state';
import * as socket from '../lib/socket';
import { Pointers } from '../pointers';
import Straw from '../straw';
import Users from '../users';

import { ChooseStrawHint } from './choose-straw-hint';
import { DrawComplete } from './draw-complete';

function onStrawClicked(index: number) {
  socket.chooseStraw(index);
}

function getWinner(winner: string, users: Record<string, User>) {
  const transitions = useTransition(winner, {
    config: { tension: 150, friction: 5 },
    from: {
      transform: `translate(0, -50px) scale(0.3)`,
      opacity: 0,
    },
    enter: {
      transform: `translate(0, 0) scale(1)`,
      opacity: 1,
    },
    leave: { transform: `translate(0, -50px) scale(0.5)`, opacity: 0 },
  });

  return (
    <>
      {transitions((style, winner) =>
        winner ? (
          <>
            <animated.div style={style} className="winner center">
              <p>{users[winner].name} wins!</p>
            </animated.div>
            <div className="center">
              <a href="/">Back to the homepage</a>
            </div>
          </>
        ) : null
      )}
    </>
  );
}

export default function Draw() {
  const {
    straws,
    users,
    isSpectator,
    winner,
    phase,
    hasClaimed,
    positions,
    taps,
    userId,
  } = useDrawState();

  const drawCompleteVisible =
    phase === PHASES.REVEAL && straws.every((straw) => straw.length === 0);

  const chooseHintVisible =
    !isSpectator && straws.every((straw) => straw.userId !== userId);

  return (
    <Fragment>
      <div>
        <Users />
        {getWinner(winner, users)}
        <DrawComplete visible={drawCompleteVisible} />
        <ChooseStrawHint visible={chooseHintVisible} />
        {isSpectator && <p className="spectator">Spectating</p>}
        <div className={`straws-container`}>
          {straws.map((straw, index) => (
            <Straw
              key={index}
              index={index}
              straw={straw}
              users={users}
              strawCount={straws.length}
              winner={winner}
              disabled={isSpectator}
              onStrawClicked={onStrawClicked}
              userClaimed={hasClaimed}
              canCancel={phase === PHASES.DRAW && straw.userId === userId}
            />
          ))}
        </div>
      </div>
      <Pointers users={users} positions={positions} taps={taps} />
    </Fragment>
  );
}
