import { useEffect } from 'react';

import * as socket from '../lib/socket';
import { resetStore } from '../lib/store';

import { DrawState } from './use-draw-state';

export const useNavigation = (drawState: DrawState) => {
  useEffect(() => {
    if (!drawState.roomId) {
      const [, , roomId] = window.location.pathname.split('/');
      if (roomId && drawState.connected) {
        socket.joinRoom(roomId);
      }
    }
  }, [drawState.connected]);

  useEffect(() => {
    const historyHandler = () => {
      const [, , roomId] = window.location.pathname.split('/');
      if (roomId) {
        socket.joinRoom(roomId);
      } else {
        socket.leaveRoom();
        resetStore();
      }
    };

    window.addEventListener('popstate', historyHandler);
    return function cleanup() {
      window.removeEventListener('popstate', historyHandler);
    };
  }, []);
};
