import { useEffect, useState } from 'react';

import { PHASES } from '../../types/game-phases';
import { playSound, SOUNDS, stopSound } from '../lib/sound';

import { DrawState } from './use-draw-state';

export const useGameSounds = (drawState: DrawState) => {
  const [currentPhase, setCurrentPhase] = useState(drawState.phase);
  const [currentUsers, setCurrentUsers] = useState(drawState.users);

  useEffect(() => {
    if (currentPhase !== drawState.phase) {
      if (drawState.phase === PHASES.DRAW) {
        playSound(SOUNDS.MUSIC, { volume: 0.3 });
        playSound(SOUNDS.WHISTLE);
      } else if (drawState.phase === PHASES.REVEAL) {
        stopSound(SOUNDS.MUSIC);
        playSound(SOUNDS.GONG);
      } else if (drawState.phase === PHASES.RESULT) {
        playSound(SOUNDS.CHEER);
      }
      setCurrentPhase(drawState.phase);
    }
  }, [drawState.phase]);

  useEffect(() => {
    if (
      Object.keys(currentUsers).length < Object.keys(drawState.users).length
    ) {
      playSound(SOUNDS.POP);
      setCurrentUsers(drawState.users);
    }
  }, [drawState.users]);

  useEffect(() => {
    if (drawState.phase === PHASES.REVEAL) {
      playSound(SOUNDS.SLIDEUP);
    }
  }, [drawState.straws]);
};
