import React, { MouseEvent, useEffect, useRef } from 'react';
import querystring from 'query-string';

import config from '../../config';
import * as socket from '../lib/socket';

export default function Start() {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputEl.current && inputEl.current.focus();
  });

  const onCreateRoomClicked = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!inputEl?.current?.value?.trim()) return;
    socket.createRoom();
    socket.setPurpose(inputEl.current.value);
  };

  const qs = querystring.stringify({
    client_id: config.slack.clientId,
    scope: 'commands',
    redirect_uri: config.slack.redirectUrl,
  });

  return (
    <div className="center">
      <p>
        Can&apos;t decide who should do that unpleasant task? Draw straws to
        decide!
      </p>
      <p>
        Enter a question, share the URL and watch each person pick a straw live
        in your browser.
      </p>
      <p>What do you want to decide?</p>
      <div>
        <form>
          <input type="text" ref={inputEl} placeholder="Who buys the donuts?" />
          <br />
          <button onClick={onCreateRoomClicked}>
            <i className="fas fa-play"></i> Get started
          </button>
        </form>
      </div>
      <p>
        <a href={`${config.slack.oauthAuthoriseUrl}?${qs}`}>
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </p>
      <p style={{ marginTop: '4rem' }}>
        Want to play something with a bit more skullduggery?
        <br />
        Why not try:{' '}
        <span className="chalice-ad">
          <a href="https://drinkthechalice.link/">Drink the Chalice</a>{' '}
          <img src="/images/chalice.png" alt="" />
        </span>
      </p>
    </div>
  );
}
