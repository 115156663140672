import React from 'react';
import { animated, config, useTransition } from '@react-spring/web';

import { useDrawState } from '../hooks/use-draw-state';

export default function Users() {
  const { spectators, users } = useDrawState();
  const numViewers = spectators - (Object.keys(users).length ?? 0);
  const transitions = useTransition(
    Object.keys(users).map((id) => users[id]),
    {
      keys: Object.keys(users),
      config: config.wobbly,
      from: {
        transform: `scale3d(0,0,1)`,
        opacity: 0,
      },
      enter: {
        transform: `scale3d(1,1,1)`,
        opacity: 1,
      },
      leave: {
        transform: `scale3d(0,0,1)`,
        opacity: 0,
      },
    }
  );

  return (
    <div className="users">
      {transitions((style, item) => (
        <animated.span
          className="user"
          style={{ backgroundColor: item.color, ...style }}
        >
          <i className={`fas fa-user${item.connected ? '' : '-times'}`}></i>{' '}
          {item.name}
        </animated.span>
      ))}
      {numViewers > 0 && (
        <span className="user">{`${numViewers} spectator${
          numViewers > 1 ? 's' : ''
        }`}</span>
      )}
    </div>
  );
}
